/*================================================================================
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Version: 2.0
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap.scss';
@import './input-number-picker-style.scss';
@import '../../@core/scss/react/libs/rc-slider/rc-slider.scss';

.sub-top-menu {
  position: relative;
  top: 85px;
  z-index: 990;

  .btn:not(:last-child) {
    margin-right: 1rem;
  }
}

.app-with-sidebar {
  .left-sidebar {
    border: 2px solid #008AEA;
    width: 220px;
  }
  @include media-breakpoint-down(xs) {
    .left-sidebar {
      margin-right: 0 !important;
      width: auto;
    }
    .search-bar-wrapper {
      width: 100% !important;
    }
  }
}

.tab-container {
  .nav-link {
    color: $primary;
  }
}

.clear-mb-of-p {
  p, ul, li {
    margin-bottom: 0;
  }
}

.react-select {
  .clear-mb-of-p {
    p {
      line-height: 1rem;
    }
  }
}

.custom-table-hover {
  tbody tr:hover, tfoot tr:hover {
    background-color: rgba($color: #D8E5EE, $alpha: .25);
    cursor: auto;
  }
  .form-group .custom-control-label {
    cursor: pointer;
  }
  thead.un-upper-case th {
    text-transform: unset !important;
  }
}

.text-decoration-line-through {
  text-decoration: line-through;
}

// overwrite dropdown menu item
.dropdown-menu {
  .dropdown-item:focus {
    color: white;
  }
}

.cursor-help {
  cursor: help;
}
